<template>
    <div class="edit-admin">
        <router-link to="/admins" class="btn btn-light mb-3">
            <span class="fas fa-chevron-left mr-1"></span>
            Back
        </router-link>
        <ui-card title="Personal information">
            <div v-if="loading" class="py-5 text-center">
                <custom-spinner class="spinner-border-md" />
            </div>
            <form-admin v-show="!loading" ref="formAdmin" :disabled-inputs="['email']" :hidden-inputs="['role', 'profile']" />
            <div v-show="!loading" class="text-end">
                <ui-button :loading="updating" variant="primary" @click="submit">
                    Update
                </ui-button>
            </div>
        </ui-card>
        <ui-card title="Permissions">
            <form-scopes v-show="!loading" ref="formScopes" />
        </ui-card>
        <div id="floatingBar" class="floating-bar" v-if="showFloatingButton" >
            <button id="floatingButton" class="floating-button" @click="submit">
                <span class="fas fa-spinner fa-spin" v-if="updating"></span>
                Update
            </button>
        </div>
    </div>
</template>

<script>
import FormAdmin from '@/components/forms/FormAdmin.vue';
import FormScopes from '@/components/forms/FormScopes.vue';
import CustomSpinner from '@/components/ui/CustomSpinner.vue';
import UiButton from '@/components/ui/buttons/Button'
import UiCard from '@/components/ui/Card.vue';

export default {
    components: {
        FormAdmin,
        CustomSpinner,
        UiButton,
        UiCard,
        FormScopes,
    },
    data() {
        return {
            updating: false,
            loading: false,
            showFloatingButton: false,
            buttonPosition: null
        };
    },
    async mounted() {
        await this.getAdmin();
        this.buttonPosition = this.$refs['formAdmin'].$el.offsetTop;
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
         async getAdmin() {
            try {
                this.loading = true;

                const { data } = await this.$axios.get(`/_/admins/${this.$route.params.id}`);
                const formAdmin = this.$refs['formAdmin'];
                const formScopes = this.$refs['formScopes'];

                const allProfiles = await this.getProfiles();
                formScopes.allProfiles = allProfiles;
                formScopes.initialScopes = 1;
                let filteredProfiles = allProfiles.filter(profile => {
                    return profile.role.includes(data.role) && (profile.name === data.profile || profile.id === data.profile);
                });

                formAdmin.formData = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    phone: data.phone,
                    active: data.active,
                    address: {
                        country: data.address?.country,
                        city: data.address?.city,
                        street: data.address?.street,
                        postal_code: data.address?.postal_code,
                        locale: data.address?.locale,
                        district: data.address?.district,
                        external_number: data.address?.external_number,
                    },
                };

                formScopes.formData = {
                    role: data.role,
                    profile: filteredProfiles[0]?.id || null,
                    scopes: data.scopes,
                };

            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
        async submit() {
            const formAdmin = this.$refs['formAdmin']
            const formScopes = this.$refs['formScopes']
            const validated = await formAdmin.validate();
            const validatedScopes = await formScopes.validate();

            if (!validated || !validatedScopes) {
                const error = formScopes.v$.$errors[0] || formAdmin.v$.$errors[0];
                const element = document.getElementById(error.$property);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                } else {
                    this.$toast.error("Please fill in all required fields.");
                }
                return;
            }

            try {
                this.updating = true;

                const formData = formAdmin.formData;
                const profiles = formScopes.allProfiles;
                
                const profile = profiles.find(profile => profile.id === formScopes.formData.profile);
                const payload = { ...formData, profile: profile?.name || null, scopes: formScopes.formData.scopes, role: formScopes.formData.role };
                const role = this.toTitleCase(formScopes.formData.role);

                await this.$axios.put(`/_/admins/update/${this.$route.params.id}`, payload);
                this.$toast.success(`${role} updated successfully`);
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.updating = false;
            }
        },
        handleScroll() {
            const scrollPosition = window.scrollY;
            if (scrollPosition > this.buttonPosition) {
                this.showFloatingButton = true;
            } else {
                this.showFloatingButton = false;
            }
        },
        async getProfiles() {
            const { data } = await this.$axios.get('/_/profiles/filters');
            return data.docs;
        },
        toTitleCase(str) {
            return str.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
    },
};
</script>

<style scoped>
.floating-bar {
  position: fixed;
  bottom: 50px;
  transform: translateX(-50%);
  left: 90%;
  width: 80%;
  max-width: 200px;
  height: 60px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  @media (max-width: 768px) {
    left: 80%;
    max-width: 120px;
    height: 50px;
  }
}

.floating-button {
  padding: 10px 60px;
  background-color: #68AE34;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

    @media (max-width: 768px) {
        padding: 5px 20px;
    }
}

.floating-button:hover {
  background-color: #68AE34;
}
</style>