<template>
    <div v-if="!loading" class="form-adming row">
        <div class="col-md-12">
            <div>
                <h6 class="admin-scopes__subtitle">Permissions</h6>
                <p class="text-muted">Select the role and profile related to this user.</p>
            </div>
        </div>
        <div v-if="!isHidden('role')" class="col-md-6 mb-4">
            <div v-if="formData.role !== 'superadmin'">
                <ui-select
                    id="role"
                    name="role"
                    class="test-class w-100"
                    label="Role"
                    option-label="key"
                    option-value="value"
                    v-model:value="formData.role"
                    :options="roles"
                    :field="v$.formData.role"
                    :disabled="isDisabled('role')"
                />
            </div>
            <div v-else>
                <ui-input
                    id="role"
                    name="role"
                    class="test-class w-100"
                    label="Role"
                    v-model:value="formData.role"
                    :field="v$.formData.role"
                    :disabled="isDisabled('role') || disabled"
                />
            </div>
        </div> 
        <div v-if="!isHidden('profile')" class="col-md-6 mb-4">
            <ui-select
                id="profile"
                name="profile"
                class="test-class w-100"
                label="Profile"
                option-label="key"
                option-value="value"
                v-model:value="formData.profile"
                :options="profiles"
                :field="v$.formData.profile"
                :disabled="isDisabled('profile') || no_profiles || formData.role === 'superadmin' || disabled"
            />
        </div> 
        <div class="col-md-12 mb-4" v-if="!isHidden('scopes')" >
            <div>
                <h6 class="admin-scopes__subtitle">Scopes</h6>
                <p class="text-muted">Select the scopes related to this profile.</p>
            </div>
            <scopes-list id="scopes" @selectedScopes="handleScopes" :preSelectedScopes="scopes" :disabled="disabled"/>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'

import UiInput from '@/components/ui/Input.vue';
import UiSelect from '@/components/ui/Select.vue';
import UiSwitch from '@/components/ui/Switch.vue';
import ScopesList from '@/components/admins/scopes/ScopesList.vue';
import { scopes } from '@/data';

import admin_scopes_mixin from '@/mixins/admin-scopes.mixin';

export default {
    name: 'FormAdmin',
    components: { UiInput, UiSelect, UiSwitch, ScopesList },
    mixins: [admin_scopes_mixin],
    props: {
        defaultData: {
            type: Object,
            default: () => {},
            required: false,
        },
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            roles: [
                {
                    key: 'Admin',
                    value: 'admin',
                },
                {
                    key: 'User',
                    value: 'user',
                },
            ],
            allProfiles: [],
            profiles: [],
            formData: {
                role: '',
                profile: '',
                scopes: [],
            },
            no_profiles: false,
            disabled: false,
            loading: false,
            initialScopes: 0,
            scopes: [],
        };
    },
    watch: {
        'formData.role': {
            handler() {
                this.$nextTick(() => {
                    this.loadProfiles();
                });
            },
            deep: true,
            lazy: true,
        },
        'formData.profile': {
            handler() {
                this.loadScopes();
            },
            deep: true,
            lazy: true,
        }
    },
    methods: {
        loadProfiles() {
            this.disabled = false;
            if (this.formData.role === 'superadmin') {
                this.disabled = true;
                this.scopes = scopes.map(scope => scope.scopes?.map(scope => scope.key)).flat();
                this.profiles = [];
             }
            else {
                this.profiles = this.allProfiles.filter(profile => {
                    return profile.role.includes(this.formData.role);
                }).map(profile => {
                    return {
                        key: profile.name,
                        value: profile.id,
                    };
                });
            }

        },
        loadScopes() {
            if (this.formData.role === 'superadmin') {
                this.disabled = true;
                this.scopes = scopes.map(scope => scope.scopes?.map(scope => scope.key)).flat();
                return;
            } 
            else if (this.initialScopes === 1) {
                this.scopes = this.formData.scopes;
                this.initialScopes = 0;
                return;
            } else if (this.formData.profile && this.formData.role) {
                const profile = this.allProfiles.find(profile => {
                    if ((profile.id === this.formData.profile || profile.name === this.formData.profile) && profile.role === this.formData.role ) {
                        return profile;
                    }
                });
                this.scopes = profile?.scopes || [];
                return;
            } else {
                this.scopes = [];
            }
        },
        handleScopes(scopes) {
            if (this.formData.role === 'superadmin') {
                this.formData.scopes = [];
            } 
            if (!this.formData.scopes?.length) {
                this.formData.scopes = scopes;
            } else {
                this.formData.scopes = [];
                this.formData.scopes = scopes;
            }
        },
        isDisabled(inputName) {
            return this.disabledInputs.includes(inputName);
        },
        isHidden(inputName) {
            return this.hiddenInputs.includes(inputName);
        },
    },
}
</script>

<style lang="scss">
.form-admin{
    &__subtitle {
        color: $ecart-secondary-500;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
    }

    &__switch {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }
}
</style>